module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"lessBabel":true,"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/vercel/path0","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"鱼鱼魔法觉醒","short_name":"HPMA","start_url":"/","background_color":"#3e2e1f","theme_color":"#3e2e1f","display":"minimal-ui","icon":"src/images/hufflepuff-pride.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8afe1cdbef946c89d8f872c8b177e405"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
